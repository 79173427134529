export default [
  {
    title: 'Students',
    icon: 'UsersIcon',
    route: 'students',
  },
  {
    title: 'Facilitators',
    icon: 'UsersIcon',
    route: 'facilitators',
  },
  {
    title: 'Courses',
    icon: 'BookIcon',
    route: 'courses',
  },
  {
    title: 'Course Categories',
    icon: 'BoxIcon',
    route: 'categories',
  },
  {
    title: 'Transactions',
    icon: 'GridIcon',
    route: 'apps-transactions',
  },
  {
    title: 'Events',
    icon: 'CalendarIcon',
    route: 'events',
  },
  {
    title: 'Testimonials',
    icon: 'EditIcon',
    route: 'testimonials',
  },
  {
    title: 'Banners',
    icon: 'ImageIcon',
    route: 'banners',
  },
  {
    title: 'Affiliate Brands',
    icon: 'GridIcon',
    route: 'brands',
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    route: 'apps-settings',
  },
  {
    title: 'Admin',
    icon: 'UserIcon',
    children: [
      {
        title: 'Profile',
        route: 'pages-account-setting',
      },
      {
        title: 'Users',
        route: 'pages-users',
      },
    ],
  },  
  // {
  //   title: 'Rates & Charges',
  //   icon: 'DollarSignIcon',
  //   route: 'apps-rate-charges',
  // },
  // {
  //   title: 'Feedback & Complains',
  //   icon: 'HeadphonesIcon',
  //   route: 'apps-supports',
  // },
  // {
  //   title: 'ePin Requests',
  //   icon: 'RefreshCwIcon',
  //   route: 'apps-epin-requests',
  // },
  // {
  //   title: 'Refer & Earn',
  //   icon: 'GitMergeIcon',
  //   route: 'apps-referrals',
  // },
  // {
  //   title: 'BHVA Admin Earning',
  //   icon: 'BriefcaseIcon',
  //   route: 'pages-earnings',
  // },
  
]
 